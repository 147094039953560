<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Minha conta
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Dados do seu usuário
            </div>
          </template>

          <v-form
            lazy-validation
            autocomplete="new-password"
          >
            <v-text-field
              v-model="account.nome"
              autocomplete="new-password"
              label="Nome"
              name="name"
              type="text"
              disabled
            />

            <v-text-field
              v-model="account.email"
              autocomplete="new-password"
              label="E-mail"
              name="email"
              type="text"
              disabled
            />

            <v-text-field
              id="codigoStyllus"
              v-model="account.codigoStyllus"
              autocomplete="new-password"
              label="Código Styllus"
              name="codigoStyllus"
              type="codigoStyllus"
              disabled
            />

            <v-text-field
              id="password"
              v-model="account.senha"
              autocomplete="new-password"
              label="Senha"
              name="password"
              type="password"
            />

            <v-select
              v-model="account.perfilId"
              label="Perfil"
              :items="profiles"
              item-text="nome"
              item-value="perfilId"
              disabled
            />

            <v-btn
              color="primary"
              @click="submit"
            >
              Salvar
            </v-btn>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      account: {},
      profiles: [],
    }),
    mounted () {
      this.account = {
        nome: this.$store.getters.user.nome,
        email: this.$store.getters.user.email,
        codigoStyllus: this.$store.getters.user.codigoStyllus,
        perfilId: this.$store.getters.user.perfilId,
      }
      this.$http.get('/perfis')
        .then(resp => {
          this.profiles = resp.data.rows
        })
    },
    methods: {
      submit () {
        this.$http.put('/account', this.account)
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
          })
      },
    },
  }
</script>
